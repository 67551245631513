import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';

import { getRequestState, hideError } from '../../stores/request';

const RequestError = (props) => {
  const { hideError, error, hasError } = props;
  return (
    <Modal
      show={hasError}
      onHide={hideError}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Woops! Looks like we hit a snag</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {' '}
        {error && error.message
          ? error.message
          : 'Something went wrong and we are working on a solution. Please try again.'}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { hasError, error } = getRequestState(state);

  return {
    hasError,
    error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ hideError }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestError);
