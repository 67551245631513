// import styles from './orderSuccess.module.scss';

const OrderSuccess = (props) => {
  const { company } = props;
  return (
    <div>
      <p>
        Within <strong>{company.turnAroundTime} business days</strong>{' '}
        you&apos;ll get an email from the {company.shortName} with a thumbs up
        or thumbs down. If your order request is approved, the acceptance email
        will provide clear instructions on next steps (e.g. payment, fill date,
        pickup instructions, date and location).
      </p>
      {/* <div className={styles.share}>
        <h4>
          Spread the word. Custom cans with {company.shortName} brews are
          awesome.
        </h4>
      </div> */}
    </div>
  );
};
export default OrderSuccess;
