const OrderApproved = () => {
  return (
    <div>
      <p>
        The order has been approved and the customer has been emailed next
        steps.
      </p>
    </div>
  );
};
export default OrderApproved;
