import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import * as modals from './index';
import { getRequestState, hideSuccess } from '../../stores/request';
import { CompanyInterface } from '../../models/company';

type SuccessProps = {
  message: string;
  successComponent?: string;
  hasSuccess: boolean;
  hideSuccess: () => void;
  closeCallback?: () => void;
  company: CompanyInterface;
};

const RequestSuccess = (props: SuccessProps) => {
  const {
    hasSuccess,
    message,
    successComponent,
    hideSuccess,
    company,
    closeCallback
  } = props;
  const handleClose = () => {
    hideSuccess();
    if (closeCallback) {
      closeCallback();
    }
  };
  if (!successComponent) {
    return <div />;
  }
  const ModalComponent = modals[successComponent];
  return (
    <Modal
      show={hasSuccess}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{message}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalComponent company={company} />
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const {
    hasSuccess,
    message,
    successComponent,
    closeCallback
  } = getRequestState(state);

  return {
    hasSuccess,
    message,
    successComponent,
    closeCallback
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ hideSuccess }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestSuccess);
