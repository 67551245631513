// import styles from './orderSuccess.module.scss';

const PaymentSuccess = (props) => {
  const { company } = props;
  return (
    <div>
      <p>
        Thank you for your payment! Please remember that ALL the important
        information about your order is in your order confirmation email. Review
        the email and add the pickup date and address to your calendar!
      </p>
      <p>
        NOTE: {company.shortName} will ONLY contact you IF SOMETHING CHANGES
        with your order.
      </p>
    </div>
  );
};
export default PaymentSuccess;
